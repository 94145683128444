import { ShopPageNavProps } from "@bluebottlecoffee/design-system/components";
import { collectionPage, shopPage } from "../link-builders";
import {
  SanityShopCollectionFilter,
  ShopCollectionNavQueryResult,
} from "../sanity/collection-queries";
import { PageLink } from "../sanity/shared";
import { onlyUnique } from "../utils";

export type TagsList = {
  [key: string]: string[];
};

export function toShopPageNavItems({
  region,
  lang,
  navLinks,
  trackClickCollection,
}: {
  region: string;
  lang: string;
  navLinks: ShopCollectionNavQueryResult[];
  trackClickCollection?(display: string, slug: string, url: string): any;
}): Pick<ShopPageNavProps, "navItems">[] {
  function toNavItems(pages: PageLink[]) {
    return pages.map(({ name, slug }) => {
      const link = collectionPage({ region, lang, slug });

      return {
        link,
        display: name,
        onClick: () => trackClickCollection?.(name, slug, link),
      };
    });
  }

  return navLinks
    .filter(
      (links) =>
        links.shopCollections?.length > 0 &&
        links.shopCollections?.every((collection) => collection !== null),
    )
    .map(({ name, slug, shopCollections }) => {
      const link = shopPage({ region, lang, slug });
      const navItems = toNavItems(shopCollections);

      const allBubble = {
        link,
        display: `All ${name}`, // todo: i18n the word All
        onClick: () => trackClickCollection?.(name, slug, link),
      };

      navItems.unshift(allBubble);

      return {
        navItems,
      };
    });
}

/**
 * Converts filters into tags
 * @param filters
 * @returns
 */
export function toTags(filters: SanityShopCollectionFilter[]): TagsList {
  if (!filters) return {};

  return filters.reduce((result, filter) => {
    const { name } = filter;
    const tags = filter.tags?.map((tag) => tag.display) ?? [];
    const isEmpty = !result[name];

    if (isEmpty) return { ...result, [name]: tags };
    else {
      const combinedTags = [...result[name], ...tags].filter(onlyUnique);
      return {
        ...result,
        [name]: combinedTags,
      };
    }
  }, {});
}
