import {
  MiniCart,
  SlideOverDialog,
} from "@bluebottlecoffee/design-system/components";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import { loadStripe } from "@stripe/stripe-js";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  useAnalytics,
  useCart,
  useCheckout,
} from "@chordcommerce/react-autonomy";
import { Currency } from "dinero.js";
import {
  CartLineItem,
  SubscriptionInterval,
} from "@bluebottlecoffee/design-system/components/lib/types";
import useVariantAvailability from "../lib/chord/hooks/components/use-variant-availability";
import useVariantPrice from "../lib/chord/hooks/components/use-variant-price";
import { isFeatureEnabled } from "../lib/utils/is-feature-enabled";
import { AlgoliaRecommendProduct } from "../lib/algolia/types";
import {
  SourceProductRec,
  transformProductRecsToShopCards,
} from "../lib/transformers/product-recs";
import { Cart as CartSchema } from "../lib/sanity-schema";
import { CartBaseProps, SharedQueryKey } from "../lib/sanity/shared";
import {
  continueToCheckout,
  toCartTableProps,
  toFreeShippingBarProps,
  toGiftMessageFormProps,
  toLineItemsProps,
  toOrderSummaryProps,
  toProductRecsCopyProps,
  toProductRecsProps,
  toRemovePromoAlertProps,
} from "../lib/transformers/cart";
import { toLinkProps } from "../lib/transformers/link";
import { toMiniCartProps } from "../lib/transformers/mini-cart";
import {
  cartVariants,
  discountTotal,
  getPromoLabels,
  getPromoWithCode,
  isFreeStandardShipping,
  shouldRenderFreeShippingBar,
} from "../lib/utils";
import emailIsValid from "../lib/utils/strings/email-is-valid";
import {
  getRecommendedProducts,
  productRecsWithBestValueVariant,
} from "../lib/utils/product-recs/product-recs";

export interface DialogData
  extends Pick<
    CartBaseProps,
    | "lang"
    | "region"
    | typeof SharedQueryKey.SUBSCRIBABLE_PRODUCTS
    | SharedQueryKey.PRODUCT_RECS
    | SharedQueryKey.NAV_AND_CART_INFO_BANNER
  > {
  miniCartCopy: CartSchema;
  giftCardFormCopy: GiftCardFormCopy;
}

function validate(data: CartSchema) {
  if (!data.dialog.heading) {
    throw Error("MiniCart must have a heading.");
  }
}

export const MiniCartDialog: FunctionComponent<DialogData> = ({
  miniCartCopy,
  giftCardFormCopy,
  subscribableProducts,
  navAndCartInfoBanner,
  productRecs: staticProductRecs,
  ...defaultProps
}) => {
  const { lang, region } = defaultProps;
  const [promoSuccess, setPromoSuccess] = useState<boolean>(true);
  const [promoErrorMessage, setPromoErrorMessage] = useState<string>(
    miniCartCopy.orderSummary.promoCopy.errorMessage[lang],
  );

  const {
    cart,
    loadCart,
    isFetching,
    isLoaded,
    addToCart,
    addSubscription,
    removeFromCart,
    modifyLineItem,
    addPromoCode,
    removePromoCode,
    modifyCart,
    modifyGiftCards,
  } = useCart();
  const { prepareCheckout } = useCheckout();

  const { trackCartViewed, trackProductClicked } = useAnalytics();
  const [loadingCart, setLoadingCart] = useState<boolean>(!isLoaded);
  const [checkoutError, setCheckoutError] = useState<string | null>(null);

  const [productRecs, setProductRecs] = useState<
    SourceProductRec[] | AlgoliaRecommendProduct[]
  >(staticProductRecs.slice(0, 3));
  const [cartLoaded, setCartLoaded] = useState(false);

  useEffect(() => {
    loadCart()
      .then(() => {
        setCartLoaded(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message);
      });
  }, [loadCart]);

  useEffect(() => {
    if (isFetching) {
      setLoadingCart(true);
    } else {
      setLoadingCart(false);
    }
  }, [isFetching]);

  const handleModifyQuantity = useCallback(
    async (lineItemId, quantity) => {
      setLoadingCart(true);
      await modifyLineItem({
        lineItemId,
        attributes: { quantity: Math.max(quantity, 1) },
      });
      setLoadingCart(false);
    },
    [modifyLineItem],
  );

  const handleModifyCart = useCallback(
    async (lineItem: CartLineItem, intervalId) => {
      setLoadingCart(true);

      const {
        variant: { sku, subscriptionOptions },
        id,
        quantity,
      } = lineItem;

      await removeFromCart({ lineItemId: id });

      const { options, intervals } = subscriptionOptions;

      const { length, unit, _id } = intervals.find(
        (interval) => interval._id === intervalId,
      );

      const subscriptionQuantity =
        options?.find((opt) => opt.quantityLabel.quantity === quantity) ??
        options[options.length - 1];

      try {
        if (_id === "one-time-purchase") {
          await addToCart({
            sku,
            quantity,
          });
        } else {
          await addSubscription({
            sku,
            quantity: subscriptionQuantity.quantityLabel.quantity,
            interval: {
              length,
              unit,
            },
            endDate: null,
          });
        }
      } catch (e) {
        loadCart();
        setLoadingCart(false);
        console.error(e);
      } finally {
        setLoadingCart(false);
      }
    },
    [modifyCart],
  );

  validate(miniCartCopy);

  useEffect(() => {
    loadCart().catch((error) => {
      console.error(error.message);
    });
  }, [loadCart]);

  const handleRemoveFromCart = async (lineItemId: number) => {
    setLoadingCart(true);
    try {
      await removeFromCart({ lineItemId });
      setLoadingCart(false);
      return {
        success: true,
        message: "Item successfully removed from cart.", // TODO localize
      };
    } catch (error) {
      setLoadingCart(false);
      console.error(error.message);
      return {
        success: false,
        message: error.message,
      };
    }
  };

  const onAddPromoCodeSubmit = async (promoCode: string) => {
    try {
      setLoadingCart(true);
      await addPromoCode({ promoCode });
      setLoadingCart(false);
      setPromoSuccess(true);
      setPromoErrorMessage("");
    } catch (error) {
      setPromoSuccess(false);
      setPromoErrorMessage(error.message);
      console.error(error);
    }
  };

  const handleRemovePromoCode = async (promoCode: string) => {
    try {
      setLoadingCart(true);
      await removePromoCode({ promoCode });
      setLoadingCart(false);
      setPromoSuccess(true);
      setPromoErrorMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveGiftMessage = async () => {
    try {
      await modifyCart({
        attributes: {
          // @ts-ignore
          giftNoteAttributes: {
            from: "",
            recipient: "",
            note: "",
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onAddGiftMessage = async (
    to: string,
    from: string,
    giftMessage?: string,
  ) => {
    try {
      await modifyCart({
        attributes: {
          // @ts-ignore
          giftNoteAttributes: {
            from,
            recipient: to,
            note: giftMessage,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const transformedGiftNote =
    cart.giftNote?.from && cart.giftNote.recipient
      ? {
          to: cart.giftNote.recipient,
          from: cart.giftNote.from,
          giftMessage: cart.giftNote.note,
        }
      : null;

  const miniCartProps = toMiniCartProps(
    miniCartCopy,
    giftCardFormCopy,
    region,
    lang,
  );

  function isCartEmpty(): boolean {
    return cart.lineItems?.length === 0;
  }

  useEffect(() => {
    const recommendEnabled = isFeatureEnabled(
      process.env.NEXT_PUBLIC_ENABLE_ALGOLIA_RECOMMEND,
    );
    if (recommendEnabled && cartLoaded && !isCartEmpty()) {
      getRecommendedProducts({
        cart,
        lang,
        setProductRecs,
      });
    }
  }, [cartLoaded, cart.lineItems]);

  const handleAddToCardProductRecs = async (
    sku: string,
    quantity: number,
    interval: SubscriptionInterval,
  ) => {
    try {
      if (interval) {
        addSubscription({
          interval,
          quantity,
          sku,
          endDate: null,
        });
      } else {
        addToCart({ sku, quantity });
      }
    } catch (e) {
      loadCart();
      console.error(e);
    }
  };

  return (
    <SlideOverDialog
      className="!p-0"
      contentType="minicart"
      showDismissButton={false}
      overflowY={false}
      dismissText={miniCartCopy.dialog.dismissText[lang]}
      title={miniCartCopy.dialog.heading[lang]}
      onOpen={trackCartViewed}
      secondDismissButton={!cart.lineItems || cart.lineItems?.length === 0}
      secondDismissText={
        toLinkProps(miniCartCopy.returnToShopLink, region, lang).text
      }
    >
      <MiniCart
        {...miniCartProps}
        showReturnToShopButton
        showHeading
        cartProps={{
          ...miniCartProps.cartCopy,
          cartTableProps: {
            ...toCartTableProps(miniCartCopy, lang, giftCardFormCopy),
            removeFromCart: handleRemoveFromCart,
            modifyQuantity: handleModifyQuantity,
            modifyCart: handleModifyCart,
            cartLineItems: cartVariants({
              data: cart,
              dialect: { region, lang },
              subscribableProducts,
              oneTimeLabel: miniCartCopy.oneTimePurchase[lang],
            }),
            cartView: "mini",
            modifyGiftCards: async (options) => {
              await modifyGiftCards({
                giftCards: {
                  giftCardsDetails: options.giftCardsDetails.map(
                    ({ id, ...rest }) => ({
                      id: id.toString(),
                      rest,
                    }),
                  ),
                },
              });
            },
            emailIsValid,
            loadingCart,
            useVariantPrice,
            useVariantAvailability,
          },
          infoBanner:
            navAndCartInfoBanner?.showInfoBanners &&
            navAndCartInfoBanner.text[lang],
          renderFreeShippingBar: shouldRenderFreeShippingBar(cart),
          freeShippingBarProps: {
            ...toFreeShippingBarProps(miniCartCopy.freeShippingBar, lang),
            estimatedTotal: {
              amount: Math.round(
                (Number(cart.itemTotal) + Number(cart.adjustmentTotal)) * 100,
              ),
              currency: cart.currency as Currency,
            },
            freeShipPromoCode: isFreeStandardShipping(cart),
          },
          orderSummaryProps: {
            ...toOrderSummaryProps(miniCartCopy.orderSummary, lang),
            bgColor: miniCartCopy.dialog.bgColor,
            cartView: "mini",
            lineItems: {
              ...toLineItemsProps(miniCartCopy.orderSummary.lineItems, lang),
              credit:
                Number(cart.totalApplicableStoreCredit) !== 0.0
                  ? cart.displayTotalApplicableStoreCredit
                  : undefined,
              currency: cart.currency as Currency,
              discountTotal: discountTotal(cart),
              estimatedTotalCost: cart.displayOrderTotalAfterStoreCredit
                ? cart.displayOrderTotalAfterStoreCredit
                : undefined,
              freeShipping: isFreeStandardShipping(cart),
              grossSubtotal: cart.displayItemTotal
                ? cart.displayItemTotal
                : miniCartCopy.orderSummary.lineItems.tbd[lang],
              promoLabels: getPromoLabels(cart),
              shippingCost:
                cart.shipTotal === "0.0" ? undefined : cart.displayShipTotal,
              variantsTotal: cart.totalQuantity
                ? cart.totalQuantity
                : undefined,
            },
            checkoutError,
            giftMessageForm: {
              ...toGiftMessageFormProps(
                miniCartCopy.orderSummary.giftMessageCopy,
                lang,
              ),
              giftMessageProp: transformedGiftNote,
              removeGiftMessage: onRemoveGiftMessage,
              addGiftMessage: onAddGiftMessage,
            },
            promoForm: {
              error: !promoSuccess,
              promoCode: getPromoWithCode(cart),
              promoCopy: {
                add: miniCartCopy.orderSummary.promoCopy.add[lang],
                apply: miniCartCopy.orderSummary.promoCopy.apply[lang],
                placeholder:
                  miniCartCopy.orderSummary.promoCopy.placeholder[lang],
                errorMessage: promoErrorMessage,
              },
              ...toRemovePromoAlertProps(
                miniCartCopy.orderSummary.promoCopy,
                lang,
              ),
              removePromoCode: handleRemovePromoCode,
              addPromoCode: onAddPromoCodeSubmit,
            },
            /* TODO
             * subsPresent needs to be connected to data.subscriptionInCart, however, that
             * introduces a bug where if subscriptionInCart is true, then the user removes the subscription,
             * the Checkout Button remains inactive and the guest cannot checkout. @jesdeck
             */
            subsPresent: false,
            ctaClick: continueToCheckout({
              modifyCart,
              prepareCheckout,
              onError: setCheckoutError,
            }),
          },
          productRecsProps: {
            addToCartClick: handleAddToCardProductRecs,
            emptyCart: isCartEmpty(),
            productRecs: toProductRecsProps(
              productRecs,
              region,
              lang,
              cart.currency as Currency,
            ),
            productRecsCopy: miniCartCopy.productRecsCopy[lang],
            productRecShopCards: transformProductRecsToShopCards(
              productRecsWithBestValueVariant(productRecs, lang),
              lang,
              region,
              (product) => trackProductClicked(product),
            ),
            ...toProductRecsCopyProps(miniCartCopy, lang),
          },
        }}
      />
    </SlideOverDialog>
  );
};
