import { SuperHeroProps } from "@bluebottlecoffee/design-system/components";
import {
  Hero as HeroSchema,
  HeroResponsive as HeroResponsiveSchema,
} from "../sanity-schema";
import { toHeroProps } from "./hero";

export function toSuperHeroProps(
  data: (HeroSchema | HeroResponsiveSchema)[],
  region: string,
  lang: string,
): SuperHeroProps {
  return { heroes: data.map((h) => toHeroProps(h, region, lang)) };
}
