import { SuperHero } from "@bluebottlecoffee/design-system/components";
import { toSuperHeroProps } from "../transformers/super-hero";
import { ShopPageResult } from "../sanity/shop-queries";
import { SanityShopCollection } from "../sanity/collection-queries";

/**
 * Takes shopPage or shopCollection data and renders a SuperHero with the
 * heroResponsive if data is available, otherwise it renders the hero.
 *
 * @param schema the page data for either shopPage or shopCollection
 * @returns a SuperHero module
 */

export const getShopHeroModule = (
  schema: ShopPageResult | SanityShopCollection,
  region: string,
  lang: string,
) => {
  const { hero, heroResponsive } = schema;
  let heroes = [];
  if (heroResponsive?.length > 0) {
    heroes = heroResponsive;
  } else {
    heroes.push(hero);
  }
  return <SuperHero {...toSuperHeroProps(heroes, region, lang)} />;
};
