import { MiniCartProps } from "@bluebottlecoffee/design-system/components";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import { Cart as CartSchema } from "../sanity-schema";
import { CartSchemaProps, toCartProps } from "./cart";

export type MiniCartSchemaProps = Omit<MiniCartProps, "cartProps"> & {
  cartCopy: CartSchemaProps;
};

export function toMiniCartProps(
  data: CartSchema,
  giftCardFormCopy: GiftCardFormCopy,
  region: string,
  lang: string,
): MiniCartSchemaProps {
  return {
    bgColor: data.dialog.bgColor,
    cartCopy: toCartProps(data, giftCardFormCopy, region, lang),
    heading: data.dialog.heading[lang],
  };
}
